import React from "react";
import { LogoImage } from "@/data";

const Footer = () => {
  const { light } = LogoImage;
  return (
    <footer className="footer_1">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-sm-6 col-md-5">
            <aside className="widget aboutwidget">
              <a href="/">
                <img src={light} alt="" />
              </a>
              <p>
              Cairn Construction was established in 2009. The Company was set up to provide specialist streetscape paving and civil engineering infrastructural works for our clients in and around the greater Leinster area.
              </p>
              <p>&nbsp;</p>
              <p>
              We are a recognised Total Quality Management company and accredited to ISO-9001, ISO-14001 and ISO-45001.
              </p>
            </aside>
          </div>
          <div className="col-lg-4 col-sm-4 col-md-4">
            <aside className="widget contact_widgets">
              <h3 className="widget_title">contact</h3>
              <p>
                Courtyard, Behan Square
                <br />
                Russell Street, Dublin 1
              </p>
              <p>Phone:</p>
              <p>Cathal Fanning - 087 2350529</p>
              <p>Myles Jordan - 087 8375899</p>
              <p>Email:</p>
              <p>
                <a href="#">info@cairn.ie</a>
              </p>
            </aside>
          </div>
          {/* <div className="col-lg-3 col-sm-2 col-md-3">
            <aside className="widget social_widget">
              <h3 className="widget_title">social</h3>
              <ul>
                <li>
                  <a href="#">
                    <i className="fa fa-twitter"></i>Twitter
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-facebook-square"></i>Facebook
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-youtube-play"></i>Youtube
                  </a>
                </li>
              </ul>
            </aside>
          </div> */}
        </div>
        <div className="row">
          <div className="col-lg-12 col-sm-12 text-center">
            <div className="copyright">
              © copyright {new Date().getFullYear()} by{" "}
              <a href="/">Cairn.ie</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
